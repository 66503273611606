import * as React from 'react';
import Layout from '../layouts/Layout';

import { QA, VisitorsCard } from '../components/ImNew';

const ImNew = () => {
  return (
    <Layout title="I'm New">
      <div style={{ marginTop: 80 }} />
      <QA>
        <VisitorsCard />
      </QA>
    </Layout>
  );
};

export default ImNew;
