const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

export const hasDatePassed = (date: string) => {
  const [month, year] = date.split(' ');

  const parsedDate = new Date(
    parseInt(year),
    months.findIndex((m) => m === month),
    20 // past middle of month, we should have started growth track already
  );

  return parsedDate > new Date();
};
