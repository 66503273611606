import * as React from 'react';

import { graphql, useStaticQuery } from 'gatsby';
import Form from '../globals/interfaces/form';
import { hasDatePassed } from '../utils/dateHelper';

interface Query {
  allForms: {
    edges: Record<'node', Form>[];
  };
}

type filter = 'all' | 'growth-track' | 'visitors-card';

export const useForms = (formFilter: filter = 'all') => {
  const { allForms } = useStaticQuery<Query>(graphql`
    {
      allForms {
        edges {
          node {
            id
            form
            date
            type
          }
        }
      }
    }
  `);

  if (formFilter === 'all')
    return allForms.edges
      .map((e) => e.node)
      .filter(
        (f) => (f.type === 'growth-track' && hasDatePassed(f.date)) || true
      );

  if (formFilter === 'growth-track')
    return allForms.edges
      .map((e) => e.node)
      .filter((f) => f.type === formFilter)
      .filter((f) => hasDatePassed(f.date));

  return allForms.edges.map((e) => e.node).filter((f) => f.type === formFilter);
};
