import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles(({ spacing, palette }: Theme) => {
  const color = palette.augmentColor({ main: '#353b48' });

  return createStyles({
    image: {
      borderRadius: spacing(0.5),
    },
    imageWrapper: {
      height: 420,
      display: 'flex !important',
      justifyContent: 'center',
      alignItems: 'center',
    },
    slider: {},
    sliderContainer: {
      '& .slick-slider': {
        width: '100%',
      },
    },
  });
});

export default useStyles;
