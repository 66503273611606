import {
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from '@material-ui/core';
import { PermContactCalendar } from '@material-ui/icons';

import * as React from 'react';

import { useForms } from '../../../hooks/useForms';

import useStyles from './VisitorsCard.styles';

const VisitorsCard = () => {
  const classes = useStyles();

  const [visitorsCard] = useForms('visitors-card');

  return (
    <Card className={classes.root}>
      <a
        style={{ textDecoration: 'none' }}
        href={visitorsCard.form}
        target="_blank"
      >
        <CardActionArea
          style={{ display: 'flex', justifyContent: 'flex-start' }}
        >
          <PermContactCalendar />
          <CardContent>
            <Typography
              className={classes.title}
              gutterBottom
              variant="h4"
              component="h2"
            >
              Visitors Card
            </Typography>
          </CardContent>
        </CardActionArea>
      </a>
    </Card>
  );
};

export default VisitorsCard;
