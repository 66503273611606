import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = makeStyles(({ breakpoints, spacing, palette }: Theme) => {
  const color = palette.augmentColor({ main: '#353b48' });

  return createStyles({
    imageWrapper: {
      marginRight: spacing(2),
    },
    image: {
      borderRadius: spacing(0.5),
      marginRight: spacing(4),
    },
    textWrapper: {
      position: 'absolute',
      zIndex: 1,
      height: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      '&:after': {
        content: '" "',
        height: 'calc(100% - 48px)',
        width: 'calc(100% - 48px)',
        border: '12px solid #FFFFFF6F',
        top: 24,
        left: 24,
        zIndex: 1,
        pointerEvents: 'none',
        position: 'absolute',
      },
    },
    title: {
      fontWeight: 600,
      color: color.main,
    },
    root: {
      margin: spacing(4, 1),
      '& svg': {
        color: color.main,
        height: '2em',
        width: '2em',
        margin: spacing(2),
      },
    },
    subText: {
      color: palette.common.white,
    },
  });
});

export default styles;
