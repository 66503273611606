import { Container, Grid, Typography } from '@material-ui/core';
import { graphql, useStaticQuery } from 'gatsby';
import {
  GatsbyImage,
  IGatsbyImageData,
  getSrc,
  StaticImage,
} from 'gatsby-plugin-image';
import React from 'react';
import YoutubeVideo from '../../Shared/YoutubeVideo/YoutubeVideo';
import useStyles from './QA.styles';
import Slider from 'react-slick';

import { GoogleMap } from '../../Contact';

interface Image {
  thumbnail: IGatsbyImageData;
  original: IGatsbyImageData;
}

interface Props {
  children?: React.ReactNode;
}

const QA = ({ children }: Props) => {
  const classes = useStyles();

  const { allFile } = useStaticQuery(graphql`
    {
      allFile(filter: { relativePath: { regex: "/gallery/" } }) {
        edges {
          node {
            childImageSharp {
              large: gatsbyImageData(height: 400, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  `);

  const images: Image[] = allFile.edges
    .filter((e) => e.node.childImageSharp)
    .map((e) => ({
      original: e.node.childImageSharp.large,
    }));

  const settings = {
    dots: true,
    infinite: true,
    speed: 2500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: classes.slider,
  };

  return (
    <Container>
      <YoutubeVideo videoUrl="https://www.youtube.com/embed/l5fI19HVOsg" />
      {children}
      <Typography
        style={{ fontWeight: 500, marginBottom: 24, marginTop: 16 }}
        variant="h4"
      >
        What to expect when you arrive?
      </Typography>
      <Typography style={{ fontSize: 18 }}>
        You are welcomed by one of our friendly hosts, if it is your first time
        visiting you will receive a free cappuccino, at our coffee station, we
        have a visitor’s card that we would love for you to fill in.
      </Typography>

      <Typography
        style={{ fontWeight: 500, marginBottom: 24, marginTop: 16 }}
        variant="h4"
      >
        What to expect during the service?
      </Typography>
      <Typography style={{ fontSize: 18 }}>
        We are a Church Community that puts great emphasis on worship and we
        encourage you to take part with us.
      </Typography>
      <Typography
        style={{ fontWeight: 500, marginBottom: 16, marginTop: 24 }}
        variant="h4"
      >
        Who is Welcome?
      </Typography>
      <Typography style={{ fontSize: 18 }}>
        If you are reading this question,{' '}
        <b style={{ fontWeight: 500 }}>YOU ARE WELCOME</b> and so are your
        family and friends.
      </Typography>
      <Typography
        style={{ fontWeight: 500, marginBottom: 16, marginTop: 24 }}
        variant="h4"
      >
        What about the kids?
      </Typography>
      <Typography style={{ fontSize: 18 }}>
        We encourage the children to worship with us and thereafter they join
        the Children's Ministry, ages 3 - 13.
      </Typography>
      <Typography
        style={{ fontWeight: 500, marginBottom: 8, marginTop: 16 }}
        variant="h4"
      >
        Our Times
      </Typography>
      <Typography style={{ fontSize: 18, marginBottom: 8 }}>
        Our online services are available every Sunday from 7am.
      </Typography>
      <Typography style={{ fontSize: 18, marginBottom: 8 }}>
        Our in person services start at 9am.
      </Typography>
      <Typography
        style={{ fontWeight: 500, marginBottom: 8, marginTop: 16 }}
        variant="h4"
      >
        Venue
      </Typography>
      <Typography style={{ fontSize: 18 }}>
        Gonubie Farmers Hall, East London Show Grounds
      </Typography>
      <GoogleMap marginTop={24} marginBottom={24} />
      <Typography
        style={{ fontWeight: 500, marginBottom: 8, marginTop: 16 }}
        variant="h4"
      >
        Contact
      </Typography>
      <Typography style={{ fontWeight: 500, fontSize: 18 }}>
        Office:{' '}
        <span style={{ fontWeight: 400 }}>admin@lifehousechurch.org.za</span>
      </Typography>
      <Typography style={{ fontWeight: 500, fontSize: 18 }}>
        Tel: <span style={{ fontWeight: 400 }}>076 990 0359</span>
      </Typography>
      <Grid
        className={classes.sliderContainer}
        style={{ marginTop: 32, height: 400 }}
        container
      >
        <Slider
          autoplay={true}
          autoplaySpeed={5000}
          className={classes.slider}
          {...settings}
        >
          {images.map((i, idx) => (
            <Grid key={idx} className={classes.imageWrapper} item>
              <GatsbyImage image={i.original} alt="gallery image" />
            </Grid>
          ))}
        </Slider>
      </Grid>
    </Container>
  );
};

export default QA;
